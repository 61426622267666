/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Beatriz & João - Confirmações"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--40 pt--80" name={"information"} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--0 pr--0 flex--center" anim={null} animS={null} style={{"maxWidth":1080}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center w--300 pt--50" content={"Confirmações"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--center fs--22 w--300 pt--06" style={{"marginTop":0}} content={"<span style=\"color: var(--color-blend--50);\">Confirmações até 01-06-2024</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--20" name={"rsvp"}>
          
          <ColumnWrap className="column__flex --center el--1 mb--20" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <ContactForm className="--shape2 --shape2 --style3 ff--3 fs--22 w--300 ls--002 lh--12" style={{"maxWidth":700}} action={"/contact"} fields={[{"name":"Estarei presente","type":"checkbox","required":true},{"name":"Não poderei estar presente","type":"checkbox"},{"name":"Nº Pessoas ","type":"select","options":["1","2","3","4","5"],"required":true,"placeholder":"Nº Pessoas"},{"name":"Nomes convidados","type":"textarea","placeholder":"Indique por favor os nomes dos convidados."},{"name":"RESTRIÇÕES ALIMENTARES","type":"checkbox","required":true},{"name":"Nenhuma","type":"checkbox"},{"name":"Vegetariano","type":"checkbox"},{"name":"Intolerâncias ","type":"checkbox"},{"name":"Quais?","type":"textarea","placeholder":"Quais?"},{"name":"Enviar","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}